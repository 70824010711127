

.o7group th {
    border-width : 0px 1px 0px 1px
}

.tooltip-text {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    width: 50%;
    color: white;
    font-size: 12px;
    background-color: #192733;
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
    text-align: left;
  }


  .hover-text:hover .tooltip-text {
    visibility: visible;
  }